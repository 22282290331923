body {
  background: url(../gfx/backdrop.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
}

.site-logo {
  width: 142px;
  position: relative;
  margin: 0 auto;

  &:after {
    content:'';
    display: block;
    width: 40px;
    margin: 30px auto;
    border-bottom: 2px solid #9DD6E4;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.l-page {
  @at-root .no-flexbox & {
    margin-top: 200px;
  }

  margin-top: 100px;
  min-height: 600px;
  // position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vw;
}

.copy {
  margin-top: 1em;
  font-size: 15px;
  line-height: 20px;
  color: #3E3E3D;
}

a {
  color: inherit;
  text-decoration: none;
  padding-bottom: .5em;
  border-bottom: 1px solid currentColor;

  &[href*=tel] {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.l-footer {
  display: flex;
  max-width: 1000px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}

.l-footer__image {
  flex-grow: 1;
  flex-basis: 33.33%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  min-width: 200px;
  margin-bottom: 50px;

  img {
    display: block;
    width: 100%;
  }
}

.copy__foot-note {
  margin-top: 1rem;
  a {
    border: none;
    img {
      display: inline-block;
    }
  }
}